import React from "react"
import { RichText } from "prismic-reactjs"
import {
  htmlSerializerUpdateStyle,
  linkResolver,
  Icon,
} from "@lesmills/gatsby-theme-common"

import type { LabelType } from "@lesmills/gatsby-theme-common"

type Props = {|
  duration: Array<LabelType>,
  name: Array<LabelType>,
  author: Array<LabelType>,
  challengeWord: Array<LabelType>,
  setPlayingVideo: () => void,
  video: {
    url: string,
  },
|}

const BasicInfo = ({
  duration = {
    text: "",
    raw: [],
  },
  name = {},
  author = {},
  video = {
    url: "",
  },
  challengeWord = {
    text: "",
    raw: [],
  },
  setPlayingVideo = () => {},
}: Props) => (
  <div className="h-full top-0/100 absolute z-10 left-0 right-0 flex flex-col justify-center items-center">
    <p className="challenge-duration text-xs md:text-3xs text-white font-inter-regular text-base uppercase track-0.84 mt-30">
      {`${duration.text.replace(" ", "-")} ${challengeWord.text}`}
    </p>
    <RichText
      render={name.raw}
      linkResolver={linkResolver}
      htmlSerializer={htmlSerializerUpdateStyle(
        "",
        "challenge-name text-62xl md:text-64xl text-white font-base-heavy text-base leading-65 uppercase"
      )}
    />
    {/* Author */}
    <div className="flex flex-row justify-center items-center">
      <span className="challenge-author-divider"></span>
      <RichText
        render={author.raw}
        linkResolver={linkResolver}
        htmlSerializer={htmlSerializerUpdateStyle(
          "",
          "text-xs md:text-3xs text-white font-inter-regular"
        )}
      />
    </div>

    <Icon
      icon="play-outline"
      type="base"
      classNames="mt-15 play-icon-base cursor-pointer bg-cover w-60 h-60"
      handleOnClick={() => setPlayingVideo(video.url)}
    />
  </div>
)

export default BasicInfo
